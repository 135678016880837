import React from 'react'
import { graphql, Link } from 'gatsby'
import { MinimalLayout, Card } from '../components'
import Helmet from 'react-helmet'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark, site } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <MinimalLayout>
      <Helmet>
        <title>
          {site.siteMetadata.title} | {frontmatter.title}
        </title>
        {/* OpenGL MetaData */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={frontmatter.title} />
        <meta property="og:description" content={markdownRemark.excerpt} />
        {/* Twitter Metadata */}
        <meta name="twitter:title" content={frontmatter.title} />
        <meta name="twitter:description" content={markdownRemark.excerpt} />
      </Helmet>
      <Card>
        <h1>[ {frontmatter.title} ]</h1>
        <hr />
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <hr />
        <section style={{ display: 'flex', justifyContent: 'space-between' }}>
          {frontmatter.prevPage !== null ? (
            <Link
              to={frontmatter.prevPage}
              style={{ fontSize: '1.25em', fontWeight: 'bolder' }}
            >
              Back
            </Link>
          ) : (
            <div />
          )}
          {frontmatter.nextPage !== null ? (
            <Link
              to={frontmatter.nextPage}
              style={{ fontSize: '1.25em', fontWeight: 'bolder' }}
            >
              Next
            </Link>
          ) : (
            <div />
          )}
        </section>
      </Card>
    </MinimalLayout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt(pruneLength: 250)
      frontmatter {
        date(formatString: "MMMM DD YYYY")
        path
        title
        nextPage
        prevPage
      }
    }
  }
`
